<template>
    <div class="header">
        <div style="width:100%;">
            <div class="title">
              <slot name="title">
                <el-page-header v-if="backAction" @back="handleBack" :icon="ArrowLeft">
                  <template #content>
                    <span class="title"> {{title}} </span>
                  </template>
                </el-page-header>
                <span v-else class="title"> {{title}} </span>
              </slot>
            </div>
            <div class="content">
                <slot name="content">{{ content }}</slot>
            </div>
        </div>
        <div>
            <slot />
        </div>
    </div>
</template>

<script setup>
import { ArrowLeft } from '@element-plus/icons-vue'
import {useAction} from "@/utils/handleAction";



const props = defineProps({
  title: {
    type: String,
  },
  backAction:Object,
  content: {
    type: String,
    default: ''
  }
})

/*** 只能在setup生命周期中执行 ***/
const {action, setData, setParams} = useAction({});

const handleBack = () => {
  if(props.backAction){
    action(props.backAction)
  }

}

</script>

<style lang="scss" scoped>
.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 16px 20px 0 20px;
    background: #fff;
    border-bottom: 1px solid #e8eaec;
    .title {
        font-size: 20px;
        position: relative;
        top:-0.5px;
    }
    .content {
        color: #909399;
        font-size: 14px;
        margin-top: 10px;
        /*padding-bottom: 15px;*/
        &:empty {
            display: none;
        }
    }
}
</style>
