<template>
  <div
    :class="{
            'page-main': true,
            'is-collaspe': collaspeData
        }" :style="pageStyle"
  >
    <div v-if="title" class="title-container">{{ title }}</div>
    <slot/>
    <div v-if="collaspeData" class="collaspe" title="展开" @click="unCollaspe">
      <el-icon>
        <el-icon-arrow-down/>
      </el-icon>
    </div>
  </div>
</template>

<script setup>
import {ref, computed} from 'vue';

const props = defineProps({
  title: {
    type: String,
    default: ''
  },
  collaspe: {
    type: Boolean,
    default: false
  },
  height: {
    type: String,
    default: ''
  },
  customStyle: {
    type: Object,
    default: () => {}
  },
})
const collaspeData = ref(props.collaspe)
const pageStyle = computed(() => {
  let style = props.customStyle || {};
  style.height = collaspeData.value ? props.height || '' : ''
  return style
})


function unCollaspe() {
  collaspeData.value = false
}
</script>

<style lang="scss" scoped>
.page-main {
  position: relative;
  margin: 0 20px;
  padding: 20px;
  border-radius: 3px;
  background-color: #fff;

  &.is-collaspe {
    overflow: hidden;

    .collaspe {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      padding: 40px 0 10px;
      text-align: center;
      font-size: 24px;
      color: #ccc;
      text-shadow: 0 0 1px #fff;
      background: linear-gradient(to bottom, transparent, #fff);
      cursor: pointer;
      transition: 0.3s;

      &:hover {
        color: #333;
      }
    }
  }

  .title-container {
    width: calc(100% + 40px);
    padding: 14px 20px;
    margin-left: -20px;
    margin-top: -20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #eee;
  }
}
</style>
