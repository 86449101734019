<template>
  <div class="menu-item-show">
    <svg-icon v-if="icon" :icon="icon" class="icon"/>
    <span class="title">{{ title }}</span>
    <span v-if="badge" class="badge badge-text">{{ badge }} </span>
  </div>
</template>

<script>
export default {
  name: "show-item",
  props: {
    icon: String,
    title: String,
    item: Object,
  },
  data() {
    return {
      badge: '',
    }
  },
  mounted() {
    // console.log('---------',this.item,this.item.spm)
    if (!this.item) {
      return
    }
    Quick.$on('badge_' + this.item.path, (data) => {
      this.badge = data
    })
    if (this.item.spm) {
      Quick.$on('badge_' + this.item.spm, (data) => {
        this.badge = data
      })
    }
    this.badge = this.item.badge
  }
}
</script>

<style lang="scss" scoped>

.el-sub-menu__title {
  .badge-text {
    right: 40px !important;
  }
}

.menu-item-show {
  //position: relative;
  //width: 100%;
}

.badge {
  z-index: 1;
  background-color: #f56c6c;
  box-shadow: 0 0 0 1px #FFFFFF;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.badge-text {
  right: 15px;
  border-radius: 10px;
  font-size: 12px;
  height: 18px;
  line-height: 18px;
  padding: 0 6px;
  text-align: center;
  white-space: nowrap;
  color: #ffffff;
}
</style>
