<template>
  <div
    class="when-field"
    :class="emitKey"
    v-if="show"
  >
    <slot></slot>
  </div>
</template>

<script>
  import {onMounted, ref, inject, getCurrentInstance} from "vue";
  import {contrasts} from "@/components/Form/Composition/FormField";
  export default {
    name: 'WhenField',
    props: {
      emitKey:{
        type:String,
        default:'when',
      },
      emit: {
        type: Array,
        default: () => []
      },
    },
    setup(props) {

      const groupForm = inject('groupForm', {});
      const bus = inject('formBus');
      const show = ref(true);
      const {proxy} = getCurrentInstance();



      /**
       * 对外提供事件驱动
       */
      const onEvent = () => {

        // if (props.emitKey) {
        //   groupForm.bus.on(props.emitKey, (data) => {
        //     const { action } = data;
        //     if(action === "show"){
        //       show.value = true
        //     }else if(action === "hidden"){
        //       show.value = false
        //     }
        //   });
        // }

        // 触发emit事件
        if (props.emit && props.emit.length) {

          props.emit.forEach((data) => {
              const { name, condition, option, event, yes, no } = data;
            groupForm.bus.on(name ,(eventData) => {
                const {form,action,data} = eventData
                if(action !== event){
                  return
                }
                const res = contrasts(condition, option, data);
                const actionStr = res ? yes : no;
                if(actionStr === "show"){
                  show.value = true
                }else if(actionStr === "hidden"){
                  show.value = false
                }
              })
            }
          );
        }
      };

      onMounted(() => {
        onEvent();
      });


      return {
        show
      }
    },
  }
</script>
<style scoped>

</style>
