<template>
  <div class="quick-tab" :class="{'tab-mb-0':removeBottom}">
    <slot name="header"></slot>
    <el-tabs v-model="activeName" v-bind="attrs" @tab-change="tabChange">
      <el-tab-pane
        v-for="(pane,index) in panes" :key="index"
        v-bind="pane.props"
      >
        <template
          v-for="(child , index ) in pane.children"
          v-slot:[child.props.slot]
          :key="index"
        >
          <json-render
            :key="index"
            :render-data="child"
          />
        </template>
      </el-tab-pane>
    </el-tabs>
    <slot name="footer"></slot>
  </div>
</template>
<script>
import InteractsWithQueryString from '@/mixins/InteractsWithQueryString'
import {inject, ref, onMounted, onBeforeMount, watch, getCurrentInstance, computed} from "vue";
import {useRoute, useRouter} from "vue-router";
export default {
  name: 'QuickTabs',
  mixins: [InteractsWithQueryString],
  props: {
    removeBottom: {
      type: Boolean,
      default: false
    },
    default: {
      type: String,
      default: ''
    },
    tabKey: {
      type: String,
      default: 'tab_key'
    },
    refreshKey: {
      type: String,
      default: 'repage'
    },
    isFilter: {
      type: Boolean,
      default: false
    },
    showStep:{
      type: Boolean,
      default: false
    },
    panes: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    defaultAttrs() {
      return {
        ...this.$attrs
      }
    },

    attrs() {
      return {
        ...this.defaultAttrs
      }
    }
  },
  setup(props){
    const routeInfo = useRoute();
    const router = useRouter();
    const activeName = ref('')
    const { proxy } = getCurrentInstance();
    const curIndex = computed(() => {
      let index = 0
      for (let i = 0;i < props.panes.length;i++){
        if(props.panes[i].props.name === activeName.value){
          index = i
          break
        }
      }
      return index
    })

    function initTab() {
      if (props.isFilter) {
        if (routeInfo.query[props.tabKey]) {
          activeName.value = routeInfo.query[props.tabKey]
        }
      }
    }
    function activeTab(i) {
      activeName.value = i
      // console.log('-activeName--',value)
    }

    watch(() => routeInfo,() => {
      initTab()
    })
    watch(() => props.default,() => {
      activeName.value = props.default
    })
    onBeforeMount(() => {
      activeName.value = props.default
    })

    /**
     * 处理filter
     * */
    function tabChange(e) {
      // console.log('---------e',e)
      if (props.isFilter) {
        proxy.updateQueryString({
          [props.tabKey]: activeName.value,
          [props.refreshKey]: Math.random()
        })
      }
    }



    const nextStep = () => {
      // activeName.value = '1'
      if(curIndex.value < (props.panes.length-1)){
        const nextIdx = curIndex.value+1
        if(props.panes[nextIdx]){
          const nextIndex = props.panes[nextIdx].props.name
          form.validateForm((res) => {
            if(res.length){
              Quick.error(res[0].message)
            }else{
              activeName.value = nextIndex
              if(nextIdx+1 === props.panes.length){
                nextBtn(false)//隐藏按钮
              }
            }
          },props.panes[curIndex.value].props.fields)
        }
      }
    }

    const prevStep = () => {
      if(curIndex.value > 0){
        const prevIdx = curIndex.value-1
        if(props.panes[prevIdx]){
          let prevIndex = props.panes[prevIdx].props.name;
          activeName.value = prevIndex
        }
      }

    }

    const groupForm = inject('groupForm', {});
    const form = inject('form');
    const nextBtn = (show) => {
      form.bus.emit('show-step-btn',{type:'next',params:show})
    }
    const prevBtn = (show) => {
      form.bus.emit('show-step-btn',{type:'prev',params:show})
    }
    const submitBtn = (show) => {
      form.bus.emit('show-step-btn',{type:'submit',params:show})
    }

    if(form){
      form.bus.on('event-step',(type) => {
        if(type === 'next'){
          nextStep()
        }
        if(type === 'prev'){
          prevStep()
        }
      })
    }

    const handleBtn = () => {
      if(!props.showStep){
        return false
      }
      if(curIndex.value === 0){
        prevBtn(false)
        nextBtn(true)
        submitBtn(false)
      }else if(curIndex.value + 1 === props.panes.length){
        prevBtn(true)
        nextBtn(false)
        submitBtn(true)
      }else{
        submitBtn(false)
        prevBtn(true)
        nextBtn(true)
      }
    }
    watch(() => activeName.value,() => {
      handleBtn()
    },{immediate:true})
    onMounted(() => {
      initTab()
    })

    return {
      nextStep,
      initTab,
      activeTab,
      tabChange,
      activeName,
    }
  }
}
</script>

<style lang="scss" scoped>

  .quick-style-1{
    :deep(.el-tabs) {
      .el-tabs__header .el-tabs__nav {
        .el-tabs__active-bar {
          z-index: 0;
          width: 100%;
          background-color: #e1f0ff;
          border-right: 2px solid #409eff;
        }
        .el-tabs__item {
          text-align: left;
          /*padding-right: 100px;*/
        }
      }
      .el-tab-pane {
        padding: 0 20px 0 30px;
      }
    }
  }
  .tab-mb-0{
    :deep(.el-tabs__header){
      margin-bottom: 0px;
    }
    :deep(.el-tabs__header){
      width: 100%;
      margin-right:0px;
      margin-left:0px;
    }
    :deep(.el-tabs__active-bar.is-left){
      width: 3px;
    }
  }
</style>
