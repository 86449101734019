<template>
  <el-dropdown v-bind="dropdownProps">
    <slot name="show"> </slot>
    <template #dropdown>
      <el-dropdown-menu class="user-dropdown">
        <template
          v-if="actions.length">
          <el-dropdown-item v-for="(action,index) in actions" :key="index" command="">
            <json-render :render-data="action"/>
          </el-dropdown-item>
        </template>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<script>
import {computed, ref, useAttrs} from "vue";

export default {
  name: "index",
  props: {
     showCom:{
      type:String,
      default:"title"
    },
    size:{
      type:String,
      default:"small"
    },
    field:{
      type:String,
      default:"field"
    },
    actions:{
      type:Array,
      default:() => [],
    }
  },
  setup(props){

    const attrs = useAttrs();

    /**
     * label
     */
    const dropdownProps = computed(() => {
      return {
        ...props,
        ...attrs
      };
    });

    return {
      dropdownProps
    }
  }
}
</script>

<style scoped>

</style>
