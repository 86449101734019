<template>
  <default-field
    v-model="value"
    v-bind="labelProps"
    @reset="resetField"
    ref="def"
  >
    <div style="display:block;text-align: left;">
      <div>
        <el-popover :visible="visible" placement="right" :width="600">
          <select-area :selected="value" :options="options" :disable="disable" @cancel="handleCancel" @confirm="handleConfirm"> </select-area>
          <template #reference>
            <el-button :disabled="disabled"  plain @click="visible = true">{{__('Select')}}</el-button>
          </template>
        </el-popover>
        <el-button  type="danger" plain @click="value = []" :disabled="disabled" v-if="value.length"  >{{__('Clean')}}</el-button>
      </div>
      <div class="tag-box" v-if="value.length" >
        <el-tag  effect="plain"
                 :closable="closable && !disabled"
                 style="margin:2px;"
                 v-for="(tag,index) in tagList"
                 :key="index"
                 @close="handleClose(index,tag)" >{{tag.fullName}}</el-tag>
      </div>
    </div>

  </default-field>
</template>

<script>

  import {computed, ref, useAttrs, onMounted} from "vue";
  import {baseProps} from '../Composition/FormField';

  export default {
    name: 'SelectAreaField',
    props: {
      ...baseProps,
      options: {
        type: [Array],
        default: () => []
      },
      props: {
        type: [Object, Array],
        default: () => {
        }
      },
      disable:{
        type: [Array],
        default: () => []
      },
    },
    setup(props) {

      const attrs = useAttrs();
      const value = ref([]);
      const def = ref(null);


      const fieldAttrs = computed(() => {
        return {
          ...attrs,
          disabled: props.disabled,
        };
      });

      /**
       * label
       */
      const labelProps = computed(() => {
        return {
          ...props,
          ...attrs
        };
      });


      /** 接管验证 start ******/
      const validate = () => {
        return false;
      };

      /** 处理表单重置 **/
      const resetField = (data) => {

        if (data) {
          value.value = data;
          return;
        }
        value.value = initialValue();
      };

      /**
       * 初始化值
       */
      const initialValue = () => {
        return !(props.default === undefined || props.default === null)
          ? props.default
          : [];
      };


      const optionsMap = new Map

      onMounted(() => {
        getOptionsMap(JSON.parse(JSON.stringify(props.options)),optionsMap)
        setOptionsName(optionsMap)
        value.value = initialValue();
        // console.log('---------select area',value.value)
      })

      function setOptionsName (optionsMap) {

        for (let key of optionsMap.keys()){
          let item = optionsMap.get(key)
          let fullName = item.label
          if(item.pid && optionsMap.get(item.pid)){
            const pItem = optionsMap.get(item.pid)
            fullName = pItem.label + '-' + fullName
            if(pItem.pid &&  optionsMap.get(pItem.pid)){
              const ppItem = optionsMap.get(pItem.pid)
              fullName = ppItem.label + '-' + fullName
            }
          }
          item.fullName = fullName
          optionsMap.set(key,item)
        }

      }
      function getOptionsMap (list,mapData,fillNmae) {
        list.forEach((item,index) => {
          if(item.children){
            getOptionsMap(item.children,mapData)
          }

          if(fillNmae){
            let fullName = item.label
            if(item.pid && mapData.get(item.pid)){
              const pItem = mapData.get(item.pid)
              fullName = pItem.label + '-' + fullName
              if(pItem.pid &&  mapData.get(pItem.pid)){
                const ppItem = mapData.get(pItem.pid)
                fullName = ppItem.label + '-' + fullName
              }
            }
            item.fullName = fullName
          }
          mapData.set(item.value,item)
        })
      }

      /**
       * blur 事件验证数据
       */
      const handleBlur = () => {
        def.value.validate('blur');
      };



      const visible = ref(false)
      const closable = ref(true)

      const tagList = computed(() => {
          let list = []
          value.value.forEach((id) => {
            let item =  optionsMap.get(id)
            if(item){
              list.push(item)
            }
          })
          return list
      })

      const handleCancel = () => {
        visible.value = false
      }

      const handleConfirm = (data) => {
        visible.value = false
        value.value = data
      }
      const handleClose = (index) => {
        value.value.splice(index,1)
      }
      return {
        handleBlur,
        resetField,
        validate,
        handleClose,
        handleConfirm,
        handleCancel,
        tagList,
        optionsMap,
        labelProps,
        fieldAttrs,
        closable,
        visible,
        value,
        def,
      }
    }
  }
</script>
<style scoped lang="scss">

.area-box::-webkit-scrollbar {
  width: 8px;
}
.tag-box::-webkit-scrollbar {
  width: 8px;
}
.tag-box{
  border: 1px solid #dcdfe6;
  border-radius: 5px;
  padding: 10px 4px;
  max-width: 500px;
  max-height: 170px;
  overflow:auto;
  margin-top: 10px;

}

.area-box {
  max-height: 250px;
  overflow-y: auto;
  margin-right: 10px;
  width: 250px;

  .check-active {
    color: #79bbff;
  }

  .item-box {
    margin: 0px 0px 2px;
    display: block;
  }

}
</style>
