<template>
  <div :style="{'margin-bottom': '15px',height:height}">
    <el-card shadow="hover" :style="{height:height}">
      <div style="font-size: 14px;padding-bottom: 12px;" class="dir-left-nowrap main-between" v-if="mode === 'full'">
        <div>{{ title }}</div>
        <div>
          <el-tag v-if="tagText" v-bind="tagProps">{{ tagText }}</el-tag>
        </div>
      </div>
      <div class="card-box " :class="iconAlign == 'right' ? 'dir-left-nowrap':'dir-right-nowrap'">

        <div class="box-text">
          <span class="box-text-num">{{ number }}</span>
          <span class="box-text-str" v-if="mode !== 'full'">{{ title }}</span>
        </div>
        <div class="card_box_cir " v-if="percentageNum == -1" :style="{background: iconOuterBackground}">
          <div class="card_box_cir1" :style="{background: iconInnerBackground}">
            <quick-icon :size="iconSize" :icon="icon" class="icon" :color="iconColor"/>
          </div>
        </div>

       <div   class="dir-top-nowrap cross-center"  v-else>
         <el-progress type="dashboard" :percentage="percentageNum" v-bind="percentageProps">
           <template #default="{ percentage }">
             <span style="font-size: 10px;color:#98a9bc;">{{ percentageTitle }}</span>
             <span style="font-size: 10px;color:#98a9bc;">{{ percentage }}%<quick-icon size="12" :icon="compareIcon"
                                                                                       class="icon"
                                                                                       :color="compareColor"/></span>
           </template>
         </el-progress>
         <div style="font-size: 12px;color:#98a9bc;" v-if="percentageText">{{ percentageText }}</div>
       </div>

      </div>
      <div style="font-size: 14px;color:#98a9bc;" :class="iconAlign == 'right'  ? 'dir-left-nowrap':'dir-right-nowrap'" >
        <div class="dir-left-nowrap cross-baseline">
          <span v-if="compareTitle">{{ compareTitle }}</span>
          <span v-if="compareText" :style="{marginLeft: '10px',color:compareColor,fontSize: '12px'}">
           {{ compareText }}<quick-icon size="12" :icon="compareIcon" class="icon" :color="compareColor"/>
        </span>
        </div>
      </div>

    </el-card>
  </div>

</template>

<script>
export default {
  name: 'IconCard',
  props: {
    href: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: 'full'
    },
    iconAlign: {
      type: String,
      default: 'right'
    },
    title: {
      type: String,
      default: '总数'
    },
    number: {
      type: String,
      default: '0'
    },
    tagText: {
      type: String,
      default: ''
    },
    tagProps: {
      type: Object,
      default: () => {
        return {size: 'small'}
      }
    },
    compareTitle: {
      type: String,
      default: ''
    },
    compareColor: {
      type: String,
      default: '#ed4014'
    },
    compareText: {
      type: String,
      default: ''
    },
    compareIcon: {
      type: String,
      default: 'el-icon-CaretTop' //el-icon-CaretTop   el-icon-CaretBottom
    },
    icon: {
      type: String,
      default: 'el-icon-TrendCharts'
    },
    iconColor: {
      type: String,
      default: '#ffffff'
    },
    iconSize: {
      type: String,
      default: '30'
    },
    iconOuterBackground: {
      type: String,
      default: '#deeeff'
    },
    iconInnerBackground: {
      type: String,
      default: '#409EFF'
    },
    percentageNum: {
      type: Number,
      default: -1
    },
    percentageTitle: {
      type: String,
      default: ''
    },
    percentageText:{
      type: String,
      default: ''
    },
    percentageProps: {
      type: Object,
      default: () => {
        return {
          width: 60,
        }
      }
    },
    height:{
      type:String,
      default:'auto'
    },
  },
  methods: {
    test() {
      console.log('----test card-->',)
      this.dispatch('Test', 'test3')
    }
  }
}
</script>

<style lang="scss" scoped>
.icon-card {
  text-align: center;
}

.icon-card .icon {
  transition: all .3s ease-in-out;
}

.icon-card:hover .icon {
  transform: scale(1.15);
}

.card-box {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border-radius: 4px;
  justify-content: space-between;

  .card_box_cir {
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    overflow: hidden;
    //margin-right: 20px;

  }

  .card_box_cir1 {
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
  }


  .box-text {
    .box-text-num {
      display: block;
      color: #252631;
      font-size: 26px;
      font-weight: 600;
    }

    .box-text-str {
      display: block;
      color: #98a9bc;
      font-size: 12px;
    }
  }
}
</style>
