<template>
  <div >

    <json-render
      v-if="column.display && column.display.component"
      :render-data="column.display"
      @refresh="refresh"
      @submit="refresh"
    />
    <!--        :is="scope.row[column.name].value.component"-->
    <json-render
      v-else-if="column.value && column.value.component"
      :render-data="column.value"
      @refresh="refresh"
      @submit="refresh"
    />
    <span v-else>{{ column.value }}</span>

  </div>
</template>

<script>

export default {
  name: 'QuickColumn',
  props: {
    column: {
      type: Object,
      required: true
    }
  },
  mounted() {
  },
  computed: {
    attrs:function () {
      return this.column.props
    },
    children:function () {
      let slots = {};
      if(this.column.children && this.column.children.length){
        const list = {
          default:[]
        }
        this.column.children.forEach((item) => {
          const slot = item.slot ? item.slot:'default';
          if (list[slot]) {
            list[slot].push(item);
          } else {
            list[slot] = [item];
          }
        })
        slots =  list

      }else{
        slots.default = []
      }
      return slots
    }
  },
  methods: {
    /**
     * 刷新数据
     */
    refresh() {
      this.$emit('refresh')
    }
  }
}
</script>
<style scoped>

</style>
