<template>
 <div :style="{height: height+'px'}">
   <el-auto-resizer>
     <template #default="{ height, width }">
       <el-table-v2
         :cache="3"
         :columns="columnsList"
         :data="dataList"
         :width="width"
         :expand-column-key="expandColumnKey"
         :height="height"
       >
       </el-table-v2>
     </template>
   </el-auto-resizer>

 </div>
</template>

<script>
import {computed, h} from 'vue'
import QuickColumn2 from "@/components/Table/QuickColumn2";
export default {
  name: "TableV2",
  props:{
    columns:{
      type:[Array],
      default:() => [],
    },
    data:{
      type:[Array],
      default:() => [],
    },
    height:{
      type:Number,
      default:500,
    },
    colWidth:{
      type:Number,
      default:200,
    }
  },
  setup(props,{emit}){

    const handleRefresh = () => {
       emit('refresh')
    }
    const handleActionExecuted = () => {
       emit('actionExecuted')
    }
    const columnsList = computed(() => {
      return props.columns.map((item,index) => {
        let width = props.colWidth
        if(typeof item.props.width === 'string'){
          item.props.width = width = parseInt(item.props.width.replace(/px/,''))
        }else if(typeof item.props.width === 'number'){
          item.props.width = width = parseInt(item.props.width)
        }
        console.log('--------width',width,item)
        let s = {
          key: item.name,
          dataKey: item.name,
          title: item.title,
          width:width,
          cellRenderer: (e) => {
            // console.log('-------------fdfd',e)
            return h(QuickColumn2,{column:e.cellData,onRefresh:handleRefresh,onActionExecuted:handleActionExecuted})
          },
        }
        // if(!index){
        //   s.fixed = 'left'
        // }
        return s
      })
      // return [
      //   {
      //     key: `column-1`,
      //     dataKey: `column-1`,
      //     title: `Column 1`,
      //     width: 150,
      //     cellRenderer: (e) => {
      //       console.log('-------------fdfd',e)
      //       return h('span',{style:{color:'red'}},'dfd')
      //       // <QuickRender ></QuickRender>
      //     },
      //   },
      // ]
    })
    const dataList = computed(() => {
      return props.data
    })
    const expandColumnKey = 'title'
    return {
      expandColumnKey,
      columnsList,
      dataList
    }
  }
}
</script>

<style scoped>

</style>
